// extracted by mini-css-extract-plugin
export var ArtistDescription = "Seen-module--ArtistDescription--GXmjl";
export var ArtistInfos = "Seen-module--ArtistInfos--ket42";
export var ButtonWrapper = "Seen-module--ButtonWrapper --Uz35h";
export var CardWrapper = "Seen-module--CardWrapper--9jEcn";
export var CarrouselWrapper2 = "Seen-module--CarrouselWrapper2--pGXqj";
export var Citations = "Seen-module--Citations--6e+7G";
export var DescriptionWrapper = "Seen-module--DescriptionWrapper--nYCGT";
export var ImageWrapper = "Seen-module--ImageWrapper--j0NWR";
export var LinkWrapper = "Seen-module--LinkWrapper--4wWQ4";
export var MobileProtrait = "Seen-module--MobileProtrait--rHkhp";
export var More = "Seen-module--More---+Bg9";
export var MoreButton = "Seen-module--MoreButton--n0SnI";
export var NameWrapper = "Seen-module--NameWrapper--gqwOP";
export var PdpWrapper = "Seen-module--PdpWrapper--bzXps";
export var PhotosWrapper = "Seen-module--PhotosWrapper--gft9S";
export var ProfilWrapper = "Seen-module--ProfilWrapper--vwoPw";
export var TitleWrapper = "Seen-module--TitleWrapper--fiQjz";
export var Wrapper = "Seen-module--Wrapper--mYio8";